import Delimiter from '../../../components/common/Delimiter';
import LoadingImage from '../../../components/common/LoadingImage';
import NavHeader from '../../../components/common/NavHeader';
import SubNavigation from '../../../components/common/SubNavigation';
import ClassLinkAddress from '../../../components/common/dashboard/ClassLinkAddress';
import ClassRow from '../../../components/common/dashboard/ClassRow';
import ClassRowSkeleton from '../../../components/common/dashboard/ClassRowSkeleton';
import ClassesHeader from '../../../components/common/dashboard/ClassesHeader';
import ClassesNotFound from '../../../components/common/dashboard/ClassesNotFound';
import { companyLogo } from '../../../helpers';
import { NAVIGATION_INFO } from '../../../helpers/constants';
import { icons } from '../../../helpers/icons';
import {
  useAppDispatch,
  useAppSelector,
  useUpcomingClassesQuery,
} from '../../../hooks/hooks';
import { setIsProgramMessageShown } from '../../../store/reducers/userSlice';

const UpcomingClasses = () => {
  const dispatch = useAppDispatch();
  const { user, isProgramMessageShown } = useAppSelector(
    (state) => state.userReducer,
  );
  const { data: classes = [], isLoading } = useUpcomingClassesQuery();

  return (
    <>
      <NavHeader label="Your upcoming classes" />
      <SubNavigation items={NAVIGATION_INFO.dashboard.items} />

      {isProgramMessageShown && !!user?.program?.message && (
        <div className="px-4 lg:pl-6 guest:pl-4">
          <div className="relative mt-6 flex flex-col gap-1 max-w-[600px] p-2 pt-6 rounded border border-grey-200">
            <div className="absolute left-[8px] top-[-22px] w-[44px] h-[44px] rounded-lg border border-grey-200 bg-white">
              <LoadingImage
                className="w-full h-full rounded-md"
                src={companyLogo(user?.company)}
              />
            </div>
            <button
              className="absolute right-1 top-1"
              onClick={() => dispatch(setIsProgramMessageShown(false))}
            >
              <img className="w-[16px]" src={icons.close} alt="close" />
            </button>
            <h2 className="text-sm font-bold leading-4 text-core-dark sm:text-regular">
              {user?.program?.message?.headline}
            </h2>
            <div
              className="text-xs font-medium text-grey-500 max-h-28 overflow-hidden message-html [&>ul]:pb-0 [&>ol]:!pb-0"
              dangerouslySetInnerHTML={{ __html: user?.program?.message?.body }}
            ></div>
          </div>
        </div>
      )}

      <div className="flex flex-col pt-4">
        {classes.length === 0 && !isLoading ? (
          <div className="px-4 lg:px-8">
            <ClassesNotFound
              illustration={icons.classesIllustration}
              title="You have no upcoming classes booked"
            />
          </div>
        ) : (
          <>
            <ClassesHeader lastRowHeader="Link/Address" />
            <Delimiter />
            {isLoading && <ClassRowSkeleton />}
            {classes.map((classInfo) => (
              <ClassRow
                key={classInfo.id}
                type="upcoming"
                classInfo={classInfo}
              >
                <ClassLinkAddress classInfo={classInfo} />
              </ClassRow>
            ))}
          </>
        )}
      </div>
    </>
  );
};

export default UpcomingClasses;
